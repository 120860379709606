class ZIDXSavedSearches implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxSavedSearchesContainer";
    }
    getMatchURL(){
        return "/saved-searches/";
    }
    render(){
        // let listView=new ZIDXAccountListView();
        // listView.render();

        let obj = {
            id: "ajaxSavedSearchLoad",
            method: "get",
            // postObj: postObj,
            ignoreOldRequests: true,
            callback: function(r:string){
                console.log(r);
                let rs=JSON.parse(r);
                if(rs.success) {
                    console.log("loaded saved searches", rs.data);
                    ZIDX.$("#AccountSavedSearchesContainer", ZIDX.$(".zidxSavedSearchesContainer")).html(rs.data);

                    ZIDX.$(".zidxSavedSearchDeleteLink").on("click", function(this:HTMLAnchorElement, e:any){
                        e.preventDefault();
                        let name=ZIDX.$(this).attr("data-name");
                        let parentId=ZIDX.$(this).attr("data-parent-id");
                        if(window.confirm("Are you sure you want to delete this "+name+"?")){
                            let obj = {
                                id: "ajaxSavedSearchDelete",
                                method: "get",
                                // postObj: postObj,
                                ignoreOldRequests: true,
                                callback: function(r:string){
                                    let rs=JSON.parse(r);
                                    if(rs.success) {
                                        ZIDX.$("#"+parentId).remove();
                                    }else{
                                        alert("Unable to delete saved search, please try again later");
                                    }
                                },
                                errorCallback: function(r:string){
                                    alert("There was a temporary network problem, please try again later");
                                },
                                url: this.href
                            };
                            ZIDX.ajaxRequest(obj);

                        }
                    });
                }else{
                    alert("Unable to delete saved search, please try again later");
                }
            },
            errorCallback: function(r:string){
                alert("There was a temporary network problem, please try again later");
            },
            url: "/load-saved-searches/?email="+localStorage.getItem("zidx.email")
        };
        ZIDX.ajaxRequest(obj);
    }
}